import { Helmet } from 'react-helmet-async';

import { Box, Container, Typography } from '@mui/material';
import { useGetStatisticQuery } from 'src/services/statisticService';

export default function DashboardAppPage() {
  const { data: statisticData, isLoading: isStatisticLoading } = useGetStatisticQuery();

  return (
    <>
      <Helmet>
        <title> Techno Gallery | Admin panel </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mb: 5, textAlign: 'center' }}>
          Salam, Panelə xoş gəlmisiniz.
        </Typography>

        <Typography variant="h4" sx={{ mb: 2 }}>
          Statistika
        </Typography>

        <Box sx={{ width: '40%', border: '1px solid rgba(145, 158, 171, 0.24)', borderRadius: '4px', padding: '30px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              justifyContent: 'space-between',
              marginBottom: 2,
            }}
          >
            <Typography variant="p">Kateqoriya sayı:</Typography>
            <Typography variant="p">{statisticData?.categories}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              justifyContent: 'space-between',
              marginBottom: 2,
            }}
          >
            <Typography variant="p">Məhsul sayı:</Typography>
            <Typography variant="p">{statisticData?.products}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', justifyContent: 'space-between' }}>
            <Typography variant="p">Ümumi məhsul sayı:</Typography>
            <Typography variant="p">{statisticData?.stock}</Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
}
