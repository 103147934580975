import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useLoginMutation } from 'src/services/authService';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [hasError, setHasError] = useState(false);

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const [login, { isError, isLoading }] = useLoginMutation();

  const handleChange = (event) => {
    setHasError(false);
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleClick = async () => {
    login(form);
  };

  useEffect(() => {
    setHasError(isError);
  }, [isError]);

  return (
    <form onSubmit={handleClick}>
      <Stack spacing={3} marginBottom={4}>
        <TextField
          error={hasError}
          disabled={isLoading}
          name="email"
          label="Email"
          onChange={handleChange}
          type="email"
        />

        <TextField
          error={hasError}
          disabled={isLoading}
          name="password"
          label="Password"
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {hasError && <Alert severity="error">Yanlış Email və ya Şifrə</Alert>}
      </Stack>
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </form>
  );
}
