import { api } from './api';

export const statisticService = api.injectEndpoints({
  endpoints: (builder) => ({
    getStatistic: builder.query({
      query: (queries) => ({
        url: `/product/statistics`,
        method: 'GET',
      }),
      providesTags: ['Statistic'],
      keepUnusedDataFor: 0,
      transformResponse: (response) => response.data,
    }),
  }),
  overrideExisting: true,
});

export const { useGetStatisticQuery } = statisticService;
