import { createSlice } from '@reduxjs/toolkit';
import { authService } from 'src/services/authService';
import { profileService } from 'src/services/profileService';

const generateAvatar = (firstName, lastName) => {
  return `https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=2065D1&color=fff`;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authService.endpoints.login.matchFulfilled, (state, action) => {
      state.user = {
        ...action.payload.data.user,
        avatar: generateAvatar(action.payload.data.user.firstName, action.payload.data.user.lastName),
      };
      state.accessToken = action.payload.data.accessToken;
      state.refreshToken = action.payload.data.refreshToken;
      state.isAuthenticated = true;
    });

    builder.addMatcher(authService.endpoints.refresh.matchFulfilled, (state, action) => {
      state.accessToken = action.payload.data.accessToken;
    });

    builder.addMatcher(profileService.endpoints.getProfile.matchFulfilled, (state, action) => {
      state.user = {
        ...action.payload.data?.user,
        avatar: generateAvatar(action.payload.data.user.firstName, action.payload.data.user.lastName),
      };
    });
    builder.addMatcher(authService.endpoints.refresh.matchRejected, (state, action) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
    });
    builder.addMatcher(profileService.endpoints.getProfile.matchRejected, (state, action) => {

      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
