import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

function generateBoundary() {
  let boundary = '';
  for (let i = 0; i < 32; i++) {
    boundary += Math.floor(Math.random() * 10).toString(16);
  }
  return boundary;
}

function serializeFormData(formData, boundary) {
  const serialized = [];
  for (const [name, value] of formData) {
    serialized.push(`--${boundary}\r\nContent-Disposition: form-data; name="${name}"\r\n\r\n${value}\r\n`);
  }
  serialized.push(`--${boundary}--\r\n`);
  return serialized.join('');
}

export const BASE_FRONT_URL = 'https://technogallery.az/';

// export const API_URL = 'http://94.20.88.174:4003';
export const API_URL = 'https://apiadmin.texnogallery.az';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    // baseUrl: 'http://127.0.0.1:4003',
    baseUrl: API_URL,
    serializeRequest: (req) => {
      if (req.body instanceof FormData) {
        const boundary = generateBoundary();
        req.headers.set('Content-Type', `multipart/form-data; boundary=${boundary}`);
        req.headers.delete('Content-Length');
        req.body = serializeFormData(req.body, boundary);
      }
      return {
        url: req.url,
        options: req,
      };
    },
    prepareHeaders: (headers, { getState }) => {
      headers.set('Accept-language', 'AZ');
      const accessToken = getState()?.auth?.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Auth',
    'Category',
    'CategoryTree',
    'Brand',
    'Attribute',
    'AttributeValue',
    'Seo',
    'ProductCreateTerms',
    'RegisterTerms',
    'DeliveryTerms',
    'PaymentTerms',
    'OrderTerms',
    'Banner',
    'Offers',
    'Emploees',
    'EmployeeRole',
    'Promo',
    'OrderStatus',
    'Statistic',
    'FAMILY',
    'PURPOSE',
    'RECIPIENT',
    'Attributes',
  ],
});
