import { api } from './api';

export const profileService = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: '/role/me',
        method: 'GET',
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetProfileQuery } = profileService;
