const { createSlice } = require('@reduxjs/toolkit');

const columnSlice = createSlice({
  name: 'column',
  initialState: {
    product: {
      id: true,
      title: true,
      price: true,
      category: true,
      created_at: true,
      updated_at: true,
      actions: true,
    },
  },
  reducers: {
    setProductColumn: (state, action) => {
      state.product = { ...state.product, ...action.payload };
    },
  },
});

export const { setProductColumn } = columnSlice.actions;

export default columnSlice.reducer;
