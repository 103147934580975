import { combineReducers, configureStore, isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { api } from '../services/api';
import auth from './authSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import column from './columnSlice';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { authService } from 'src/services/authService';

export const toastConfig = {
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  position: 'bottom-right',
  theme: 'colored',
};

const rtkQueryMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    if (isRejectedWithValue(action)) {
      if (!action.meta.baseQueryMeta.request.url.includes('refresh') && action.payload?.status === 401) {
        const { auth } = getState();
        // wait for refresh token to be resolved before proceeding without Promise
        const data = await dispatch(
          authService.endpoints.refresh.initiate({
            refreshToken: auth.refreshToken,
          })
        );

        if (data?.error === undefined) {
          // get endpoint name
          const endpointName = action.meta.arg.endpointName;
          dispatch(api.endpoints[endpointName].initiate(action.meta.arg));
        }
      }

      if (!action.meta.baseQueryMeta.request.url.includes('refresh') && action.payload?.status === 403) {
        toast('Sizin bu əməliyyatı icra etmək üçün icazəniz yoxdur!', {
          type: 'error',
          ...toastConfig,
        });
      }
      toast(action.payload?.data?.messages?.[0] || action.payload?.data?.messages?.[0]?.message || 'Xəta baş verdi!', {
        type: 'error',
        ...toastConfig,
      });
    }
    if (isFulfilled(action)) {
      if (action.meta?.baseQueryMeta?.request?.method !== 'GET') {
        toast(action.payload?.message, {
          type: 'success',
          ...toastConfig,
        });
      }
    }
    return next(action);
  };

const reducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth,
  column,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['auth', 'column', 'attribute'],
    // transforms: [
    //   encryptTransform({
    //     secretKey: '8x/A%D*G-KaPdSgVkYp3s6v9y$B&E(H+MbQeThWmZq4t7w!z%C*F-J@NcRfUjXn2',
    //     onError: function (error) {
    //       // Handle the error.
    //     },
    //   }),
    // ],
  },
  reducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware)
      .concat(rtkQueryMiddleware),
});

export const persistor = persistStore(store);

export default store;
